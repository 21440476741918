export const ultimasNoticias = [
    {
        "imagen": "../assets/imgs/AgendaAmbiental2024.jpg",
        "url": "/pdf/AgendaAmbiental2024.pdf",
        "titulo" : "Agenda Ambiental 2024",
        "fecha": "Conoce nuestras conmemoraciones, aniversaiors y algunas otras fechas relevantes"
    },
    {

        "imagen": "../assets/imgs/temporada_de_particulas.jpg",
        "url": "/pdf/temporada_de_particulas.pdf",
        "titulo": "Temporada de partículas 2024 ",
        "fecha": "Todo lo que tienes que saber sobre la temporada seca-fría que se caracteriza por inversiones térmicas y mayor presencia de partículas suspendidas en el aire, o también llamada Temporada de partículas."
    },
    {
        "imagen": "../assets/imgs/inventario_EEGyCEI_Hgo.jpg",
        "url": "/pdf/inventario_ EEGyCEI_Hgo.pdf",
        "titulo": "Inventario Estatal de Emisiones",
        "fecha" : ""
    },
    {
        "imagen": "../assets/imgs/DiaEducacionAmbiental.jpg",
        "url": "/pdf/DiaEducacionAmbiental.pdf",
        "titulo": "Enero, mes de la educación ambiental",
        "fecha": "Te invitamos a conocer más sobre el origen del Día Mundial de la Educación Ambiental, así como algunas maneras y conductas ecológicas para mantener el equilibrio y la salud de nuestro planeta."
    },

    {
        "imagen": "../assets/imgs/DiaBiolog@.jpg",
        "url": "/pdf/DiaBiolog@.pdf",
        "titulo": "Conmemoración del Día Nacional de la bióloga y el biólogo",
        "fecha": "Conmemoraremos esta fecha de vital importancia para el estudio, cuidado y conservación del #MedioAmbiente con diversas actividades en el Centro de Educación y Capacitación Ambiental de la Región Huasteca."
    },

]