import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './inicio.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselImages } from '../../json/carouselImages';

const Inicio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Carousel bannerData={carouselImages} serch={true} name={'banner-principal'}/>
      <div className='container'>
        <div className='row'>
          <div className="col-12">
            <div className='secretaria_logo' >
                          <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Medio_Ambiente.svg")` }}>
                             

              </div>
            </div>
            <div className='secretaria_name'>
              <h1>SECRETARÍA DE MEDIO AMBIENTE Y RECURSOS NATURALES</h1>
            </div>
            <div className='secretaria_funciones'>
              <h3 className='title'>FUNCIONES</h3>
              <hr className='hr-gob'/>
                          <p>Diseñar, expedir, ejecutar, conducir, coordinar, evaluar y difundir la política, los programas,
                              acciones y estrategias sectoriales o estatales de preservación, protección, restauración y
                              aprovechamiento del medio ambiente, equilibrio ecológico, recursos naturales y de los servicios
                              ambientales con criterios de sustentabilidad, de conformidad con la legislación y normatividad
                              aplicable;</p>
                          <p>Proponer las Leyes, Reglamentos, normas técnicas y demás instrumentos de política ambiental,
                              tendentes a prevenir y contrarrestar la contaminación ambiental, regular el impacto y la protección
                              del ambiente y el patrimonio natural;</p>
                          <p>Establecer, en coordinación y con participación de las dependencias competentes de la
                              Administración Pública Estatal, las normas técnicas sobre la preservación y restauración de la
                              calidad del medio ambiente; sobre los ecosistemas naturales; sobre el aprovechamiento sustentable de los recursos naturales; sobre descarga de aguas residuales, y en materia de
                              residuos sólidos y de manejo especial;</p>
                          <p>Fijar los límites de emisiones, así como normar, dictaminar y evaluar las medidas y mecanismos
                              para combatir y prevenir la contaminación del aire, suelo y agua;</p>
                          <p>Diseñar y operar, en coordinación y con la participación de otras dependencias y entidades
                              competentes de la Administración Pública Estatal, los instrumentos de fomento autorizados para la
                              protección, restauración, aprovechamiento y conservación del medio ambiente;</p>
                          <p>Promover la creación y consolidación de grupos y organizaciones vinculados con la protección,
                              preservación y desarrollo sustentable del medio ambiente y los recursos naturales;</p>
                          <p>Coadyuvar con la dependencia responsable de la Administración Pública Federal y los Comités de
                              Sanidad e Inocuidad correspondientes, en el cumplimiento de la normatividad y operación de los
                              programas en materia de sanidad forestal a efecto de atender, coordinar, supervisar y evaluar las
                              campañas de sanidad forestal, relativas al ámbito de su competencia;</p>
                          <p>Evaluar, dictaminar y dar trámite a las manifestaciones de impacto ambiental, de proyectos y
                              desarrollos que presenten los sectores público, social y privado, estableciendo los criterios,
                              lineamientos y procedimientos necesarios que garanticen, en la esfera de sus atribuciones, el
                              cumplimiento irrestricto de los principios de legalidad, oportunidad y eficacia jurídica;</p>
                          <p>Promover la participación social y de la comunidad científica, en la formulación, aplicación y
                              vigilancia de la política ambiental y concertar acciones e inversiones con los sectores social y
                              privado, para la preservación, protección, restauración y aprovechamiento del ambiente; </p>
                          <p>Fomentar, validar y vigilar las políticas públicas establecidas en materia de uso eficiente,
                              mejoramiento, y operación de los sistemas de agua potable, alcantarillado y saneamiento en
                              zonas del Estado, procurando así, la gestión integral de las cuencas hidrológicas;</p>
                          <p>Las demás que en relación con su competencia, le señale el Gobernador, las leyes, reglamentos,
                              decretos, acuerdos, convenios y disposiciones vigentes en el Estado.</p>
                          <div className='btn_organigrama'>

                              <a href='/Transparencia/Rubros/DetalleRubro/1.Normatividad' target='_blank' ><button type="button" className="btn btn-primary">Normatividad</button></a> 
                              <a href='/Transparencia/Rubros/DetalleRubro/2.Estructura' target='_blank' ><button type="button" className="btn btn-primary">Organigrama</button></a>
                  
                </div>
                <div className='secretaria-redes'>
                  <a href='https://www.facebook.com/medioambienteH/' target='_blank'><i class="fa-brands fa-facebook"></i></a>
                  <a href='https://www.instagram.com/medioambiente_h/' target='_blank' ><i class="fa-brands fa-instagram"></i></a>
                  <a href='https://twitter.com/medioambiente_h?lang=es' target='_blank'><i class="fa-brands fa-twitter"></i></a>
                </div>
            </div>

          

          </div>
        </div>
        <UltimasNoticias/>
        <div className='row'>
          <div className='notes_title'>
            <h3 className='title'>TITULAR DE LA SECRETARÍA</h3>
            <hr className='hr-gob'/>
          </div>
          <div className='section_notes'>
            {
              titular.map((item, index) =>(
                  <>
                    <div key={ index } className='sec_photo'>
                      <div className='img_secretario' style={{ backgroundImage: `url("${ item.imagen }")` }}>
                      </div>
                    </div>
                    <div className='sec_container'>
                      <div className='sec_name'>
                        <h3>{ item.nombre }</h3>
                      </div>
                      <div className='sec_function'>
                        <h4>Función de la Secretaria</h4>
                        <p>{ item.funcion }</p>
                        <div className='btn_agenda'>
                          <Link to="Agenda">Conoce la agenda de la Secretaria</Link>
                        </div>
                      </div>
                    </div>
                  </>
              ))
            }
          </div>
              </div>
              <br></br><br></br>
        <div className='row'>
          <div className='section_map'>
            <div className='map_name'>
              <h3 className='title'>UBICACIÓN</h3>
              <hr className='hr-gob'/>
            </div>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3746.913644833879!2d-98.74392557939277!3d20.095896397759866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a748d452b9e9%3A0x1f13128db89a57cb!2sSEMARNATH!5e0!3m2!1ses!2smx!4v1681875395743!5m2!1ses!2smx"
                    width="100%" 
                    height="300" 
                    frameborder="0" 
                    style={{border:0, marginBottom:50}} 
                    allowfullscreen>
            </iframe>
          </div>
        </div>   
      </div>
    </>
  )
}

export default Inicio